import React, { useRef } from 'react';
import classNames from 'classnames';

import Cta from '../Cta';
import LoyaltyTitle from '../LoyaltyTitle';
import LazyImg from '../LazyImg';

import style from './style.module.scss';

const LoyaltyCardExpired = ({ logged, type, url }) => {
    const banner = useRef(null);

    const cardClasses = classNames({
        [style.card]: true
    });

    const classes = classNames({
        [style.el]: true
    });

    return (
        <div className={classes} ref={banner}>     
            <div className={cardClasses}>
                <a className={style.imageContainer} href={`${process.env.REACT_APP_DONNAD_DOMAIN}/lp/universo-donnad`}>
                    <LazyImg src="/imgs/texture/loyalty-expired-2024.png" alt="backgroundImage" />
                </a>
                {/* <div className={style.image}>
                    <div className={style.background}>
                        <LazyImg src="/imgs/texture/loyalty2.jpg" alt="backgroundImage" />
                        <LoyaltyTitle />
                    </div>
                    <img className={style.media}
                         src="/imgs/loyalty/women.svg"
                         alt="women"/>
                </div>
                <div className={style.content}>
                    <div>
                        <p className="title">
                            Ciao, programma di loyalty!
                        </p>
                        <p className="text">
                            Un saluto può essere un addio o un benvenuto.
                            Questo “ciao” ti annuncia la fine di
                            “Chi trova un’Amica Trova un Tesoro” e ti dà
                            appuntamento al prossimo anno. Vuoi sapere quando
                            inizierà l’edizione 2023?
                        </p>
                        <Cta url={`/utente/privacy-newsletter`} label="ISCRIVITI ALLA NEWSLETTER" modifiers={['loyalty']} />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default LoyaltyCardExpired;
