import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import classNames from 'classnames';

import style from './style.module.scss';
import AppContext from '../../contexts/appContext';
import { Loyalty as LoyaltyAPI, UserCustom as UserAPI } from '../../Api.js';
import { isLoyaltyActive, loyaltyStatus } from '../../utils';

import HeadingPage from '../../components/HeadingPage';
import Footer from '../../components/Footer';
import Cta from '../../components/Cta';

const MyAccount = () => {
    let { section } = useParams();
    const sections = ['profilo', 'privacy-newsletter', 'cambio-password'];
    const screenIDs = [
        'gigya-update-profile-screen-2021',
        'gigya-privacy-screen',
        'gigya-change-password-screen',
    ];
    const { state, dispatch } = useContext(AppContext);
    const history = useHistory();
    const [nav, setNav] = useState(null);
    const [screenSetId, setScreenSetId] = useState(screenIDs[0]);
    const [activeBtn, setActiveBtn] = useState(0);

    const changeSection = (i) => {
        setScreenSetId(screenIDs[i]);
        setActiveBtn(i);
        nav.slideTo(i);
    };

    const onInit = (swiper) => {
        if (section && sections.indexOf(section) !== -1) {
            const index = sections.indexOf(section);
            setScreenSetId(screenIDs[index]);
            setActiveBtn(index);
            swiper.slideTo(index);
        }
    };

    const onDeleteClick = () => {
        dispatch({
            type: 'SET_ALERT_MODAL',
            payload: {
                text: `<p><strong>Sei sicuro di voler cancellare il tuo account?</strong></p>L'operazione non è reversibile.</p>`,
                primaryCta: {
                    label: 'Elimina account',
                    onClick: () => {
                        dispatch({ type: 'SET_LOADING' });
                        UserAPI.delete('/delete').then(({ data }) => {
                            history.push('/account-cancellato');
                            dispatch({ type: 'ACCOUNT_DELETED' });
                        });
                    },
                },
                secondaryCta: {
                    label: 'Annulla',
                    onClick: () => {
                        dispatch({ type: 'UNSET_ALERT_MODAL' });
                    },
                },
            },
        });
    };

    const prop = () => {
        // Elenco dei campi richiesti
        const requiredFields = [
            'firstName',//string
            'lastName',
            'birthDay',
            'birthMonth',
            'birthYear',
            'gender',
            'data.region',
            'state',
            'city',
            'address',
            'zip',
            'data.phone.mobile',
            'data.instruction',
            'data.occupation',
            'data.shops',
            'data.dishwasher',
            'data.familyUnit',
            'data.legal.whatsapp',
            'data.consumption.hairProduct',
            'data.consumption.washingMachine',
            'data.category.interests',
            'data.category.preferences',
            'data.category.favouriteActivities',
            'data.category.pet'
        ];
        console.log(state.user);

        if(state.user.data.dishwasher=="true"){
            requiredFields.push('data.consumption.dishwasher');
        }
    
        // Funzione per accedere ai campi nidificati
        const getNestedField = (obj, path) => {
            console.log(path, path.split('.').reduce((value, key) => value && value[key], obj));
            return path.split('.').reduce((value, key) => value && value[key], obj);
        };
    
        // Calcola i campi completati
        let completedFields = 0;
        //let completedFields = requiredFields.filter(field => getNestedField(state.user, field)).length;
        requiredFields.forEach(requiredField => {
            let fieldValue = getNestedField(state.user, requiredField);

            console.log(typeof fieldValue);
            if(typeof fieldValue !== undefined){
                if(typeof fieldValue === 'object' && Object.keys(fieldValue).length > 0){
                    completedFields++;
                }
                if(typeof fieldValue === 'string' && fieldValue.length > 0){
                    completedFields++;
                }
                if(typeof fieldValue === 'number' && fieldValue > 0){
                    completedFields++;
                }
            }

        });
        console.log(requiredFields.length);
        console.log(completedFields);
        let target = requiredFields.length;
        let perc = (100 / target) * completedFields;
    
        if (perc > 100) {
            return '100';
        }
        return perc.toFixed(0); // Restituisce la percentuale con due decimali
    };

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: { type: 'myaccount' } });
        if (!!state.user) {
            window.addEventListener('gigyaScreensetAfterSubmit', function (e) {
                dispatch({
                    type: 'USER_LOGGEDIN',
                    payload: {
                        data: e.detail.gigyaEventResponse.data,
                        ...e.detail.gigyaEventResponse.profile,
                    },
                });
                isLoyaltyActive(state) && LoyaltyAPI.post('/profile-update');
            });
            window.gigya.accounts.showScreenSet({
                screenSet: 'Restyle2017-ProfileUpdate',
                startScreen: screenSetId,
                containerID: 'account',
                extraProfileFields: 'address',
            });
        }
    }, [dispatch, screenSetId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={style.page}>
            <Helmet>
                <title>Il mio profilo | DonnaD</title>
            </Helmet>

            <HeadingPage title="<h1>Il mio profilo</h1>" />

            {loyaltyStatus(state)==='full active' && 
            <div className={style.completeProfile}>
                <p>Completa il tuo profilo</p>
                <div className={style.progressBarContainer}>
                    <div className={style.progressBar}>
                        <div className={style.progress} style={{ width: prop() + "%" }}></div>
                    </div>
                    <span className={style.percentage} style={{ left: prop() + "%" }}>{prop() + "%"}</span>
                </div>
                <p className={style.points}>+1000pt</p>
            </div>}
            {loyaltyStatus(state) === 'full active' && (
                <div className={style.message}>
                    {prop() === "100" ? (
                        <span>Hai completato il tuo profilo! Riceverai 1000 punti D entro 24h</span>
                    ) : (
                        <span>Attendi qualche minuto o ricarica la pagina per vedere la barra aggiornata</span>
                    )}
                </div>
            )}   
            <div className="wrap-hidden">
                <div className="content">
                    <div className={style.head}>
                        <Swiper
                            onSwiper={setNav}
                            centeredSlides={true}
                            className="navigation"
                            spaceBetween={20}
                            slidesPerView="auto"
                            onInit={onInit}
                            onSlideChange={(swiper) =>
                                setScreenSetId(screenIDs[swiper.activeIndex])
                            }
                            breakpoints={{
                                768: {
                                    centeredSlides: false,
                                },
                            }}
                        >
                            <SwiperSlide>
                                <button
                                    className={classNames({ btnactive: activeBtn === 0 })}
                                    onClick={() => changeSection(0)}
                                >
                                    Profilo utente
                                </button>
                            </SwiperSlide>
                            <SwiperSlide>
                                <button
                                    className={classNames({ btnactive: activeBtn === 1 })}
                                    onClick={() => changeSection(1)}
                                >
                                    Privacy e newsletter
                                </button>
                            </SwiperSlide>
                            <SwiperSlide>
                                <button
                                    className={classNames({ btnactive: activeBtn === 2 })}
                                    onClick={() => changeSection(2)}
                                >
                                    Cambio Password
                                </button>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className={style.data} id="account"></div>

                {screenSetId === 'gigya-privacy-screen' && (
                    <div className={style.delete}>
                        <strong>Elimina il tuo profilo</strong>
                        <p>
                            Sei proprio sicuro di quello che stai facendo?
                            <br />
                            Sei ancora in tempo per tornare indietro!
                        </p>
                        <Cta onClick={onDeleteClick} label="Elimina Account" />
                    </div>
                )}
            </div>
            
            <Footer />
        </div>
    );
};

export default MyAccount;
